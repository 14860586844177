import * as React from 'react';
import { IConfig } from './types/Config.Type';
import { ILoaderProps } from './map/layer/ILoaderProps';
import { OnshoreAquaculture } from './map/layers/zambia/OnshoreAquaculture';
import { SoilQualityModel } from './map/layers/zambia/SoilQualityModel';
import { TopographyModel } from './map/layers/zambia/TopographyModel';
import { SocioeconomicModel } from './map/layers/zambia/SocioeconomicModel';
import { CageWaterQualityModel } from './map/layers/zambia/CageWaterQualityModel';
import { CageInfrastructureModel } from './map/layers/zambia/CageInfrastructureModel';
import { CageSiteSelection } from './map/layers/zambia/CageSiteSelection';
import { SamplingStations } from './map/layers/zambia/SamplingStations';
import { Airports } from './map/layers/zambia/Airports';
import { PowerSupply } from './map/layers/zambia/PowerSupply';
import { Towns } from './map/layers/zambia/Towns';
import { Cities } from './map/layers/zambia/Cities';
import { FishHatcheries } from './map/layers/zambia/FishHatcheries';
import { FeedDistributors } from './map/layers/zambia/FeedDistributors';
import { Waterways } from './map/layers/zambia/Waterways';

const Config: IConfig = {
  map: {
    mapKey: "pk.eyJ1IjoibG9uZ2xpbmVlbnZpcm9ubWVudCIsImEiOiJjbGF0cHF1ZWUwM2l0M3FwcDcyN3B1YXpmIn0.snFi9yTPEZ5lfQxE3h3Epg",
    greyStyle: null, // "mapbox://styles/longlineenvironment/clatpsjsl003r15okdwsdclmi",
    satelliteStyle: "mapbox://styles/longlineenvironment/clnkins3a001s01qw9k3efaj5/draft",
    longitude: 29.323099,
    latitude: -13.961988,  
    zoom: 5, 
    minZoom: 0,
    maxZoom: 14,
    bearing: 0,
    pitch: 0,
    max_bounds: [
      [15.25, -21.75],    // Southwest (lng/lat)
      [41.45,  -4.88]     // Northeast (lng/lat)
    ]
  },
  UI: {
    height: 75,
    before_layer: 'waterway-label',
    large_screen: 900,
    load_delay_base: 0,
    load_delay_random: 0,
  },

  legend_caption: "Suitability",
  suitability: [
    { name: 'Spatial constraints', color: '#aaaaaa', hidden: true },
    { name: 'Not suitable', color: '#dc1010' },
    { name: 'Moderately suitable', color: '#ff6d01' },
    { name: 'Suitable', color: '#128800' },
    { name: 'Highly suitable', color: '#0cf600' },
  ],  

  datasets: [
    {
      key: 'onshore_aquaculture',
      ids: [ 'onshore_aquaculture' ],
      title: 'Onshore aquaculture areas',
      description: 'Pond aquaculture suitability',
      group: 'aquaculture',
      active: true,
      renderer: (p: ILoaderProps) => <OnshoreAquaculture {...p}/>
    }, {
      key: 'cage_site_selection',
      ids: [ 'cage_site_selection' ],
      title: 'Offshore aquaculture areas',
      description: 'Cage aquaculture suitability',
      group: 'aquaculture',
      active: false,
      renderer: (p: ILoaderProps) => <CageSiteSelection {...p}/>
    }, {
      key: 'soil_quality_model',
      ids: [ 'soil_quality_model' ],
      title: 'Onshore soil quality suitability',
      description: 'Aquaculture soil analysis suitability model',
      group: 'atlas',
      active: false,
      renderer: (p: ILoaderProps) => <SoilQualityModel {...p}/>
    }, {
      key: 'topography_model',
      ids: [ 'topography_model' ],
      title: 'Onshore topography suitability',
      description: 'Aquaculture elevation and slope suitability model',
      group: 'atlas',
      active: false,
      renderer: (p: ILoaderProps) => <TopographyModel {...p}/>
    }, {
      key: 'socioeconomic_model',
      ids: [ 'socioeconomic_model' ],
      title: 'Onshore socioeconomic suitability',
      description: 'Onshore aquaculture socioeconomic suitability model',
      group: 'atlas',
      active: false,
      renderer: (p: ILoaderProps) => <SocioeconomicModel {...p}/>
    }, {
      key: 'cage_water_quality_model',
      ids: [ 'cage_water_quality_model' ],
      title: 'Water quality suitability',
      description: 'Lake Kariba water quality suitability model',
      group: 'atlas',
      active: false,
      renderer: (p: ILoaderProps) => <CageWaterQualityModel {...p}/>
    }, {
      key: 'cage_infrastructure_model',
      ids: [ 'cage_infrastructure_model' ],
      title: 'Offshore socioeconomic suitability',
      description: 'Offshore aquaculture socioeconomic suitability model',
      group: 'atlas',
      active: false,
      renderer: (p: ILoaderProps) => <CageInfrastructureModel {...p}/>
    }, {
      key: 'sampling_stations',
      ids: [ 'sampling_stations' ],
      title: 'Sampling stations',
      description: 'Water quality sampling stations',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <SamplingStations {...p}/>
    }, {
      key: 'airports',
      ids: [ 'airports', 'airports_symbol' ],
      title: 'Airports',
      description: 'International and domestic',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <Airports {...p}/>
    }, {
      key: 'power_supply',
      ids: [ 'power_supply', 'power_supply_symbol' ],
      title: 'Power',
      description: 'Electricity grid infrastructure',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <PowerSupply {...p}/>
    }, {
      key: 'towns',
      ids: [ 'towns', 'towns_symbol' ],
      title: 'Towns',
      description: 'Minor urban areas',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <Towns {...p}/>
    }, {
      key: 'cities',
      ids: [ 'cities', 'cities_symbol' ],
      title: 'Cities',
      description: 'Major urban areas',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <Cities {...p}/>
    }, {
      key: 'fish_hatcheries',
      ids: [ 'fish_hatcheries', 'fish_hatcheries_symbol' ],
      title: 'Fish hatcheries',
      description: 'Fingerling suppliers',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <FishHatcheries {...p}/>
    }, {
      key: 'feed_distributors',
      ids: [ 'feed_distributors', 'feed_distributors_symbol' ],
      title: 'Feed distributors',
      description: 'Feed mill and distribution infrastructure',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <FeedDistributors {...p}/>
    }, {
      key: 'waterways',
      ids: [ 'waterways', 'waterways_stroke' ],
      title: 'Waterways',
      description: 'Rivers, streams and canals',
      group: 'spatial_planning',
      active: false,
      renderer: (p: ILoaderProps) => <Waterways {...p}/>
    }
  ],
  datagroups: [
    {
      key: 'aquaculture',
      title: 'Zambia aquaculture atlas',
      description: 'Cage and pond aquaculture suitability for major species',
      exclusive: true,
    }, { 
      key: 'spatial_planning',
      title: 'Aquaculture infrastructure',
      description: 'Key value chain assets',
      exclusive: false
    }, {
      key: 'atlas',
      title: 'Aquaculture atlas models',
      description: 'Cage and pond aquaculture suitability models',
      exclusive: false
    }
  ],
  dictionary: [
    {
      key: 'suitability',
      text: 'Suitability',
      type: 'suitability'
    }, {
      key: 'ADM1_EN',
      text: 'Province'
    }, {
      key: 'area_km2',
      text: 'Area',
      unit: <>km&sup2;</>,
      type: 'number'
    },
    { key: 'name', text: 'Name' },
    { key: 'type', text: 'Type' },
    { key: 'species', text: 'Species' },
    { key: 'STATION', text: 'Station' },
    { key: 'Temp_C', text: 'Temperature', unit: 'C', type: 'number' },
    { key: 'DO_mgL-1', text: 'Dissolved oxygen', unit: 'mg L-1', type: 'number' },
    { key: 'pH', text: 'pH', type: 'number' },
    { key: 'TDS_mgL-1', text: 'Dissolved solids', unit: 'mg L-1', type: 'number' },    
    { key: 'alk_mgL-1_CaCO3', text: 'Alkalinity', unit: 'mg L-1', type: 'number' },
    { key: 'NH3-N_ugL-1', text: 'Ammonia', unit: 'μg L-1', type: 'number' }
  ]
}

export { Config }
