import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../layer/ILoaderProps';
import { LoadableSource } from '../../layer/LoadableSource';
import { Config } from '../../../Config';

const Towns = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/15656f4cf0958ea36e911628e41891aa36b125b0" {...props}>
    <Layer
      id='towns'
      type='circle'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        'circle-opacity': [ 'case', ['boolean', ['feature-state', 'hover'], false], 1, 0 ],        
        'circle-color': 'white',
        'circle-radius': 12.5,
        'circle-blur': 0
      }}
    />    
    <Layer
      id='towns_symbol'
      type='symbol'
      layout={{ 
        visibility: props.active ? "visible" : "none",
        'icon-image': 'lle_urban-21',
        'icon-size': 1,
        'icon-allow-overlap': true      
      }}
    />    
  </LoadableSource>

export { Towns } 

