import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../layer/ILoaderProps';
import { LoadableSource } from '../../layer/LoadableSource';
import { Config } from '../../../Config';

const Waterways = (props: ILoaderProps) => {
  return (
    <LoadableSource path="https://apigeostore.longline.co.uk/dataset/62e2526f6ea054afa3ec3a4efd8a1223087ceade" {...props}>
      <Layer
        id='waterways'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "white",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            0, 3,
            8, 5,
            18, 13
          ],
        }}        
      />
      <Layer
        id='waterways_stroke'
        beforeId={Config.UI.before_layer}
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}          
        paint={{ 
          "line-color": "steelblue",
          "line-width": [
            'interpolate',
            ['linear'],
            ["zoom"],
            0, 1,
            8, 2,
            18, 10
          ],
        }}
      />

    </LoadableSource>
  );
}

export { Waterways }
