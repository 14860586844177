import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../layer/ILoaderProps';
import { LoadableSource } from '../../layer/LoadableSource';
import { Config } from '../../../Config';

const FeedDistributors = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/4e90477b441f44c5f0db49a765f8e38a1858e130" {...props}>
    <Layer
      id='feed_distributors'
      type='circle'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        'circle-opacity': [ 'case', ['boolean', ['feature-state', 'hover'], false], 1, 0 ],        
        'circle-color': 'white',
        'circle-radius': 12.5,
        'circle-blur': 0
      }}
    />    
    <Layer
      id='feed_distributors_symbol'
      type='symbol'
      layout={{ 
        visibility: props.active ? "visible" : "none",
        'icon-image': 'lle_aquaculture_site-21',
        'icon-size': 1,
        'icon-allow-overlap': true      
      }}
    />    
  </LoadableSource>

export { FeedDistributors } 

