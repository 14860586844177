import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILoaderProps } from '../../layer/ILoaderProps';
import { LoadableSource } from '../../layer/LoadableSource';
import { Config } from '../../../Config';

const TopographyModel = (props: ILoaderProps) => 
  <LoadableSource path="https://apigeostore.longline.co.uk/dataset/e6fd76875acaa0ab08f314b39de66188e71cc09a" {...props}>
    <Layer
      id='topography_model'
      beforeId={Config.UI.before_layer}
      type='fill'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{ 
        'fill-color': [
          'case',
          ['==', ['get', 'suitability'], 1], Config.suitability[1].color,
          ['==', ['get', 'suitability'], 2], Config.suitability[2].color,
          ['==', ['get', 'suitability'], 3], Config.suitability[3].color,
          ['==', ['get', 'suitability'], 4], Config.suitability[4].color,
          Config.suitability[0].color,
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.8,
          0.5
        ]
      }}
    />
    <Layer
      beforeId={Config.UI.before_layer}
      type='line'
      layout={{ visibility: props.active ? "visible" : "none" }}          
      paint={{
        "line-color": [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          'gold',
          'black'
          ],
        'line-width': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          3,
          1
          ]
      }}
    />
  </LoadableSource>

export { TopographyModel } 
